import React from "react"
import { graphql, Link } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"

import styles from "./WeBelieve.module.scss"

export const fragment = graphql`
  fragment WeBelieve on WPGraphQL_Page_Sectionfields_Sections_WeBelieve {
    button {
      text
      url
    }
    content
    list {
      content
    }
  }
`

const WeBelieve = ({ content, list, button }) => {
  return (
    <section id="learnmore" className={styles.Section}>
      <div className={styles.Overlay}></div>

      <Container>
        <Row className={styles.Row}>
          <Col className={styles.ListCol} md={8}>
            <Row className={styles.Steps}>
              {list?.map((item, index) => (
                <Col key={index} md={6}>
                  <div className={styles.Box}>
                    <div
                      className={styles.Text}
                      dangerouslySetInnerHTML={{ __html: item.content }}
                    ></div>
                  </div>
                </Col>
              ))}
            </Row>
          </Col>

          <Col className={styles.TextCol} md={4}>
            <div className={styles.ContentContainer}>
              <div
                className={styles.Content}
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <Link className="btn btn-blue" to={button.url}>
                {button.text}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  aria-hidden="true"
                  focusable="false"
                  viewBox="0 0 448 512"
                >
                  <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
                </svg>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default WeBelieve
